/**
    These are global constants that you can import on to partials
*/
export const GLOBAL_BODY = document.getElementsByTagName('body')[0];
export const GLOBAL_HTML = document.getElementsByTagName('html')[0];

export const DESKTOP_NAV = document.getElementById('header-nav-desktop');

export const MOBILE_NAV = document.getElementById('nav-mobile'); // both menus container
export const MOBILE_MENU = document.getElementById('mobile-menu'); // primary menu

export const ARTICLE_SUBNAV = document.getElementById('article-subnav');

export const SEARCH_TOGGLE = document.getElementById('searchbar-toggle') as HTMLButtonElement;
export const MOBILE_TOGGLE = document.getElementById('menu-toggle') as HTMLButtonElement;
